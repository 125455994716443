import PropTypes from "prop-types"
import React, {useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container, Table } from "reactstrap"
import { Col, Grid, List, Row } from "rsuite"
import "./restructure.scss"
import Dropdown from 'react-bootstrap/Dropdown';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap"
import { connect, useDispatch } from "react-redux"
import axios from "axios"
// Redux
import {
  withRouter,
  Link,
  useHistory,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom"
import { ApiRoute } from "common/ApiRoute"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import moment from 'moment';
import Paynow from '../Paynow'
import DisputeAmount from '../DisputeAmount/index'
import Swalalert from 'sweetalert2';
import FaqList from "../Common/FaqList"
import AmountRestructure from "./NewAmountRestructure"
import bannerImg from "../../../images/misc/image-2.png"


function Restructure() {

    const [active, setActive] = useState({
      id1: true,
      id2: true,
      id3: false,
    })

    const date = moment();
    const dispatch = useDispatch()
    const api_url = ApiRoute
    const history = useHistory()
    const params = useParams()
    const [payshowAlert, setpayShowAlert] = useState(true);

    const [offersdata, setOffersdata] = useState([]);
    const [action, setAction] = useState(false)
    const [request, setRequest] = useState({
      user_id: null,
      data: null,
      token: null,
      caseid: null,
      offer_id: null
      
    })
  const [restructuredata, setrestructuredata] = useState([]);

  const [notice_unq_id, setnotice_unq_id] = useState([]);
  const [replyData, setReplyData] = useState('');
  const [isMobileView, setIsMobileView] = useState(false);
  const accordionItemRef = useRef(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [notice_pdf, setnotice_pdf] = useState([]);
  const [case_id, set_caseid] = useState([]);
  useEffect(() => {



    const searchParams = new URLSearchParams(location.search);
    const notification_message = searchParams.get('notification_message');
    const notification_type = searchParams.get('notification_type');

  /*   const notification_message = localStorage.getItem('notification_message'); */
/*     const notification_type = localStorage.getItem('notification_type'); */
    if(notification_message !=null && notification_type !=null){
      ShowNotification(notification_message, notification_type)
    }

      var values = {
        user_id: 1,
        notice_id: params.notice_id,
        token: params.token,
      }
      axios.post(api_url + "smcb-notice-details", values).then(response => {
        if(response.data.response=="success"){

        setnotice_pdf(response.data.responseData.notice_file),
        set_caseid(response.data.responseData.caseid),
          setRequest({
            ...request,
            data : response?.data?.notice_unq_id,
          })
        }else{

        }
      })
      const handleScroll = () => {
        if (window.scrollY > 100 && payshowAlert) {
         // Swalalert.fire('It is recommended to pay the full outstanding amount to avoid any implications on your creditworthiness, prevent incurring additional interest, penalties etc.');
         Swalalert.fire({
            text: 'It is recommended to pay the full outstanding amount to avoid any implications on your creditworthiness, prevent incurring additional interest, penalties etc.',
            icon: 'warning',
            confirmButtonText: 'Close',
          })
          setpayShowAlert(false); 
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, [payshowAlert]);

  const [data, setData] = useState('');

      const openPdfInNewTab = () => {

        const pdfUrl = notice_pdf; // Replace with the actual URL of your PDF file
        const fileDownloadUrl = notice_pdf;

        // Create a temporary link element
        const link = document.createElement('a');
    
        // Set the href attribute to the file download URL
        link.href = fileDownloadUrl;

        document.body.appendChild(link);
    
        // Set the download attribute with the desired file name
        //link.download = 'your-file-name.txt'; // Replace with the desired file name
    
        // Append the link to the document body
        document.body.appendChild(link);
    
        // Trigger a click on the link to start the download
        link.click();
    
        // Remove the link from the document body
        document.body.removeChild(link);


      };

      const handleLinkClickopen = () => {
        // Replace '/your-local-url' with the actual local URL you want to open
        const urlp="C:\\xampp2\\htdocs\\mediation\\storage\\app\/pdf\/filename.pdf";
        //const urlp=notice_pdf;
       // window.location.href = '/C:\\xampp2\\htdocs\\mediation\\storage\\app\/pdf\/filename.pdf';

        window.open(urlp, '_blank');
      };





  return (
    <React.Fragment>
      <MetaTags>
        <title>Restructure | Incase360</title>
      </MetaTags>
      <div className="journey">
        <div className="container">
          <div className="">
            <Grid fluid>
              <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="p-3">
                      <div className="row mb-3">
                          <div className="col-md-12 text-center">
                           <h5>We have an important notice to share with you. You can easily view the notice by clicking on the button below.</h5>
                              <a href={notice_pdf}  target="_blank" without rel="noopener noreferrer">
                              <button className="btn custom-btn"> View communication</button>
                                 {/*   <a href="#" onClick={openPdfInNewTab}>Open Local URL</a> */}
                              </a>
                              <br></br>
                              <br></br>
                             {/* <p >  You can now download the notice in a conventient PDF format</p> */}
                            
                          </div>
                        </div>
                    </div>
                </Col>
            </Grid>
          </div>
        </div>
      </div>
      <div className="restructure">
        <div className="container">
          <div className="faqs-grid">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24} lg={24}>
                <div>
                   
                    <br />
                    <List bordered ref={accordionItemRef} style={{ backgroundColor: "#ffffff !important" }}>
                      <List.Item >
                        <span className="manage-faq" onClick={() =>
                        {
                          setActive({
                            id1: !active?.id1,
                          })  
                          accordionItemRef.current.scrollIntoView({ behavior: 'smooth' })
                        }

                        }>
                          <h3>Pay Full Amount</h3>
                          {/* <i className={`fa fa-${active?.id1 ? "minus" : "plus"}`}  onClick={() =>
                          {
                            setActive({
                              id1: !active?.id1,
                            })
                            accordionItemRef.current.scrollIntoView({ behavior: 'smooth' })
                          }

                        }></i> */}
                        </span>
                        <div
                          className="dataView my-3"
                          style={{ display: active?.id1 ? "block" : "none" }}
                        >
                            <CardBody>
                              <hr className="headflatline"></hr>
                              <div className="row mb-3">
                                <div className="col-md-12 text-center">
                                <Paynow />
                                </div>
                              </div>
                            </CardBody>
                          <br />
                          <br />
                        </div>
                      </List.Item>
                      <List.Item >
                        <span className="manage-faq" onClick={() =>{
                            setActive({
                              id2: !active?.id2,
                            })
                           accordionItemRef.current.scrollIntoView({ behavior: 'smooth' })
                        } }>
                          <h3>Restructure Options </h3>
                        </span>
                        <div
                          className="dataView my-3"
                          style={{ display: active?.id2 ? "block" : "none"}}
                        >
                          { <AmountRestructure />}

                        </div>
                      </List.Item>
                    </List>
                  </div>
                </Col>
              </Row>
            </Grid>
            <br></br>
          </div>
        </div>
      </div>

      <div className="contactsection">
        <div className="container">
          <div className="journey-grid">
            <Grid fluid>
              <Row className="show-grid">
          
              </Row>
            </Grid>
          </div>
        </div>
      </div>
      
    </React.Fragment>
  )
}

export default Restructure
