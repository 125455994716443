import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { DatePicker, SelectPicker } from "rsuite"
import { UserActionService } from "Service/userActionService"
import { formatDate } from "utils/formatDate"
import { getCommunication } from "utils/getCommunication"

export default function BulkForm(props) {
  // const userList = useSelector(state => state.AllOnChangeUser.item)
  const token = useSelector(state => state.Login.token)
  const [userList, setuserlist] = useState([])
  const [selecteduser, setSelecteduser] = useState("")
  const [noticeList, setNoticelist] = useState([])
  const [batchlist, setbatchlist] = useState([])

  const [bulkData, setBulkData] = useState({
    token: token,
    schedule_on:new Date(),
  })
  const AllFetchUserBySearch = e => {
    const requestPayload = {
      token: token,
      search_text: e,
    }
    if (e.length >= 3) {
      UserActionService.getUserBySearch(requestPayload)
        .then(res => {
          if (res.data.response === "success") {
            const newArray = res.data.responseData.map(obj => ({
              label: obj.username || obj.organization_name,
              value: obj.id,
            }))
            setuserlist(newArray)
          } else {
            setuserlist([])
          }
        })
        .catch(e => {
          setuserlist([])
        })
    }
  }
  const handleBulkSendNotice = () => {
    bulkData?.mnid &&
      (bulkData?.batchName || (bulkData?.rangeTo && bulkData?.rangeFrom)) && [ getCommunication(bulkData?.mnid),
        ShowSwal(
          "Are you sure?",
          "You won't be able to revert this!",
          "warning",
          "Yes, Send it!",
          result => {
            if (result.isConfirmed) {
              let nature_of_notice =
                noticeList.find(notice => notice.notice_id === bulkData?.mnid)
                  ?.nature_of_notice || null

              ApiCall(
                bulkData,
                nature_of_notice === "USER"
                  ? "user/send-bulk-notice"
                  : "user/send-multi-bulk-notice",
                false,
                result => {
                  try {
                    let dataString = result.data
                    let responses = []
                    if (typeof dataString === "string") {
                      if (!dataString.includes("}{")) {
                        // It's a single JSON object
                        dataString = `[${dataString}]` // Wrap single JSON object in an array
                      } else {
                        // It's multiple JSON objects
                        dataString = `[${dataString.replace(/}{/g, "},{")}]` // Correctly format as an array
                      }
                      responses = JSON.parse(dataString)
                    } else {
                      responses = [dataString]
                    }
                    responses.forEach(response => {
                      if (response.response === "success") {
                        ShowNotification(response.message, "success")
                      } else {
                        ShowNotification(response.message, "danger")
                      }
                    })
                  } catch (e) {
                    console.error("Failed to parse response:", e)
                    ShowNotification("Error in processing response", "danger")
                  }
                }
              )
            }
          }
        ),
      ]
  }

  const getNoticeList = e => {
    UserActionService.getNoticeByUserId(e).then(res => {
      setNoticelist(res.data.response)
    })
  }

  const getBatchList = data => {
    UserActionService.getbatchNameByNoticeId(data)
      .then(res => {
        if (res.data.response === "success") {
          const newArray = res.data.responseData.map(obj => ({
            label: obj.batchName,
            value: obj.batchName,
          }))
          setbatchlist(newArray)
        } else {
          setbatchlist([])
        }
      })
      .catch(e => {
        setbatchlist([])
      })
  }

  return (
    <div>
      <Row>
        <Col md={4}>
          <Label>Select User:*</Label>
          <SelectPicker
            onSearch={e => AllFetchUserBySearch(e)}
            value={selecteduser}
            block
            className="selectMenu"
            data={userList}
            onChange={e => {
              setSelecteduser(e)
              getNoticeList(e)
            }}
          />
        </Col>
        <Col md={4}>
          <Label>Select Notice Type:*</Label>
          <Input
            type="select"
            disabled={!noticeList?.length > 0 ? true : false}
            defaultValue={""}
            value={bulkData?.mnid}
            onChange={e => {
              setBulkData({ ...bulkData, mnid: e.target.value })
              getBatchList({ token: token, notice_id: e.target.value })
            }}
          >
            <option value="">Select Notice Type</option>
            {noticeList?.map(opt => {
              return (
                <option value={opt.notice_id} key={opt.notice_id}>
                  {opt.notice_name}
                </option>
              )
            })}
          </Input>
        </Col>

        <Col md={4}>
          <Label>Select Batch Name:</Label>
          <SelectPicker
            disabled={!batchlist?.length > 0 ? true : false}
            value={bulkData?.batchName}
            block
            className="selectMenu"
            placeholder="Select Batch Name"
            data={batchlist}
            searchable={false}
            onChange={e => {
              setBulkData({ ...bulkData, batchName: e })
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label className="mt-2" for="rangeFrom">
            From Notice Id :*
          </Label>
          <Input
            type="text"
            disabled={
              bulkData?.batchName ||
              noticeList.filter(
                notice =>
                  notice.notice_id === bulkData?.mnid &&
                  notice.nature_of_notice === "PROXY"
              ).length > 0
            }
            placeholder="From ex. IN12-1234"
            value={bulkData?.batchName ? "" : bulkData?.rangeFrom}
            onChange={e => {
              setBulkData({ ...bulkData, rangeFrom: e.target.value })
            }}
          />
        </Col>
        <Col md={4}>
          <Label className="mt-2" for="">
            To Notice Id :*
          </Label>
          <Input
            type="text"
            disabled={
              bulkData?.batchName ||
              noticeList.filter(
                notice =>
                  notice.notice_id === bulkData?.mnid &&
                  notice.nature_of_notice === "PROXY"
              ).length > 0
            }
            placeholder="To ex. IN12-2345"
            value={bulkData?.batchName ? "" : bulkData?.rangeTo}
            onChange={e => {
              setBulkData({ ...bulkData, rangeTo: e.target.value })
            }}
          />
        </Col>
        <Col md={4}>
          <Label className="mt-2" for="">
            Except Notice Id :
          </Label>
          <Input
            type="text"
            disabled={
              bulkData?.batchName ||
              noticeList.filter(
                notice =>
                  notice.notice_id === bulkData?.mnid &&
                  notice.nature_of_notice === "PROXY"
              ).length > 0
            }
            placeholder="Except ex. IN12-23,IN12-34,IN12-45"
            value={bulkData?.batchName ? "" : bulkData?.rangeExcept}
            onChange={e => {
              setBulkData({ ...bulkData, rangeExcept: e.target.value })
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Label className="mt-2" for="">
            Schedule Date :
          </Label>
          <DatePicker 
            
            value={bulkData?.schedule_on}
            onChangeCalendarDate={e => {
              setBulkData({ ...bulkData, schedule_on: e })
            }}
            onClean={() => {
              setBulkData({ ...bulkData, schedule_on: date.toString()})
            }}
            defaultValue={new Date()}
            format="dd-MM-yyyy HH:mm"
            block
          />
        </Col>
      </Row>
      <Col style={{ marginTop: "1em" }}>
        <Button
          color="primary"
          onClick={() => {
            handleBulkSendNotice()
          }}
        >
          Submit
        </Button>
      </Col>
    </div>
  )
}
