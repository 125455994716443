const localurl = "http://localhost/incase360/"
const testingurl = "https://testapi.incase360.com"
const prodUrl = "https://api.incase360.com"

const testPdfUrl =
  "https://tools.incase360.com/notice-management-dev/template-pdf"
const PdfProdUrl = "https://tools.incase360.com/notice-management/template-pdf"


const prodAccesIDs = ["429", "152"]
const testAccesIDs = ["128", "129"]
const actualurl =
  window.location.hostname === "incase360.com"
    ? prodUrl
    : window.location.hostname === "test.incase360.com"
    ? testingurl
    : localurl

    export const ExpressUrl =
    window.location.hostname === "incase360.com"
      ? "https://tools.incase360.com/notice-management/"
      : window.location.hostname === "test.incase360.com"
      ? "https://tools.incase360.com/notice-management-dev/"
      : "https://tools.incase360.com/notice-management-dev/"
  

export const pdfUrl =
  window.location.hostname === "incase360.com"
    ? PdfProdUrl
    : window.location.hostname === "test.incase360.com"
    ? testPdfUrl
    : testPdfUrl

export const IDs =
  window.location.hostname === "incase360.com"
    ? prodAccesIDs
    : window.location.hostname === "test.incase360.com"
    ? testAccesIDs
    : testAccesIDs
export const ApiRoute = `${actualurl}/api/`
export const RouteWithoutApi = `${actualurl}/`
export const RouteForLetterHead = `${actualurl}/assets/upload/multiLetterHead/`
export const RouteForShow = `${actualurl}/assets/upload/`
