import React, { useEffect, useState } from "react"
import {
  Form,
  FormGroup,
  FormText,
  Col,
  Row,
  CustomInput,
  FormFeedback,
  Label,
} from "reactstrap"
import { FileUploader } from "react-drag-drop-files"
import {
  CheckPicker,
  CustomProvider,
  SelectPicker,
  Input,
  Button,
} from "rsuite"
import Editor from "common/Editor"
import { connect, useDispatch } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { useHistory } from "react-router-dom"
import { ShowNotification } from "common/ShowNotification"
import { useLocation } from "react-router-dom"
import {
  getAllApproveUser,
  getAllExistingNoticeIdName,
  getBatchByNoticeId,
  getChangeUserSelect,
} from "store/actions"
import { checkReport } from "utils/checkReport"
import { ShowSwal} from "common/ShowSwal"

function AutoReportComponents(props) {
  const location = useLocation()

  const requestData = {
    user_id: null,
    notice_id: null,
    batch_name: null,
    report_on: null,
    report_type: "All",
    token: props?.token,
  }

  const [request, setRequest] = useState(requestData)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()

  const selectdatanotice = props?.noticeData?.map(item => ({
    value: item?.notice_id,
    label: item?.notice_type,
  }))

  const selectdatauser = props?.userdata?.map(item => ({
    label: item?.organization_name,
    value: item?.id,
  }))

  const selectdatabatch = props?.batchData?.map(item => ({
    value: item?.batchName,
    label: item?.batchName,
  }))

  const selectReportData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Preliminary",
      value: "Preliminary",
    },
    {
      label: "Interim",
      value: "Interim",
    },
    {
      label: "Final",
      value: "Final",
    }
  ]

  const handleSubmit = e => {
    e.preventDefault()
    
    // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
    request?.notice_id &&
      request?.batch_name &&
      request?.report_on && [
        checkReport(request)
        .then((res)=>{
          if(res.data.code !== 200) {
            ShowNotification(res?.data?.message ,"danger",10000)
          }
          else {
            ShowNotification(res?.data?.message ,"success")
          }
       })
       .catch((err)=>{
        ShowNotification("Something Went Wrong!" ,"danger")
       }),
       ShowSwal(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        "Yes, Send it!",
        result => {
          if (result.isConfirmed) {
              ApiCall(request, "addAutoReportRequest", false, result => {
                if (result?.data?.response === "success") {
                  ShowNotification(result?.data?.message, "success")
                  // history.push("/admin/existingnotice")
                  setRequest({
                    ...request,
                    notice_id: null,
                    user_id: null,
                    batch_name: null,
                    report_on: null,
                  })
                  Array.from(document.querySelectorAll(["input", "select"])).forEach(
                    input => (input.value = "")
                  )
                  props?.setApiAction({
                    ...props?.apiAction,
                    action: !props?.apiAction?.action,
                  })
                } else {
                  ShowNotification(result?.data?.message, "danger")
                }
                props?.setApiAction({ ...props?.apiAction, loading: false })
              })  
            }
          }
        )

     
        
      ]
  }

  const AllFetchUserBySearch = (e) => {
    const requestPayload = {
      token: props?.token,
      search_text: e,
    }
    // console.log(e.length);
    if(e.length >= 3) {
      dispatch(getChangeUserSelect(requestPayload))
    }
  }

  // useEffect(() => {
  //   const requestPayload = {
  //     token: props?.token,
  //   }
  //   dispatch(getAllApproveUser(requestPayload))
  // }, []);

  useEffect(() => {
    setRequest({ ...request, notice_id: null })

    let formData = new FormData()
    formData.append("token", props?.token)
    formData.append("user_id", request?.user_id)

    dispatch(getAllExistingNoticeIdName(formData))
  }, [request?.user_id])

  useEffect(() => {
    setRequest({ ...request, batch_name: null })

    let formData = new FormData()
    formData.append("token", props?.token)
    formData.append("notice_id", request?.notice_id)

    dispatch(getBatchByNoticeId(formData))
  }, [request?.notice_id])

  useEffect(() => {
    request?.notice_id && request?.batch_name && request?.report_on
      ? setButtonDisabled(false)
      : setButtonDisabled(true)
  }, [request])

  return (
    <div className="card">
      <div className="card-Body m-2">
        <Form method="post" onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Label>Select User:</Label>
              <SelectPicker
                onSearch={(e) => AllFetchUserBySearch(e)}
                value={request?.user_id}
                block
                className="mb-3"
                data={selectdatauser}
                onChange={e => setRequest({ ...request, user_id: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Select Notice:</Label>
              <SelectPicker
                value={request?.notice_id}
                block
                className="mb-3"
                data={selectdatanotice}
                onChange={e => setRequest({ ...request, notice_id: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Select Batch:</Label>
              <SelectPicker
                value={request?.batch_name}
                className="mb-3"
                block
                data={selectdatabatch}
                onChange={e => setRequest({ ...request, batch_name: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Date & Time:</Label>
              <Input
                type="datetime-local"
                name="report_on"
                id="report_on"
                onChange={e => setRequest({ ...request, report_on: e })}
                block
              />
            </Col>
            <Col md={4}>
              <Label>Select Report Type:</Label>
              <SelectPicker
                value={request?.report_type}
                className="mb-3"
                block
                data={selectReportData}
                onChange={e => setRequest({ ...request, report_type: e })}
              />
            </Col>
            <Col md={4}>
              <Button
                type="submit"
                className="my-4"
                // color="green"
                color="primary"
                size="md"
                block
                disabled={buttonDisabled || props?.apiAction?.loading}
              >
                {props?.apiAction?.loading ? "Loading..." : "Submit"}
              </Button>{" "}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  noticeData: state.ExistingNoticeIdName.items,
  batchData: state.BatchByNoticeId.items,
  userdata: state.AllOnChangeUser.items,
})

export default connect(mapStateToProps)(AutoReportComponents)
